@import '../../../general/scss/settings/variables.scss';
@import '../../../general/scss/settings/mixins.scss';

.home-carousel {

    .carousel-indicators {
        bottom: -5%;
        left: 1%;
        right: 1%;
        margin-left: 0;
        margin-right: 0;
        /*justify-content: flex-start;*/
        height: rem-calc(8);

        li {
            background-color: $unipol-off-white;
            border-radius: 100%;
            height: rem-calc(8);
            width: rem-calc(8);

            &.active {
                background-color: $unipol-green;
            }
        }
    }

    .carousel-caption {
        left: 5%;
        right: 5%;
        top: 10%;
        text-align: left;

        h5 {
            font-size: 1.325rem;
            font-weight: $font-weight-semibold;
            padding-bottom: .125rem;
        }
    }

    @include media-breakpoint-up(md) {
        .carousel-indicators {
            bottom: 1%;
        }
    }

    @include media-breakpoint-up(lg) {
        .carousel-caption {
            top: 20%;

            h5 {
                font-size: 2.25rem;
                font-weight: $font-weight-bold;
                line-height: 1.2;
                padding-bottom: .5rem;
            }
        }
    }
}

.slider {
    h1 {
        text-align: center;
        margin-bottom: 25px;
        margin: 0 0 10px;
    }

    ul.bxslider {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

/*.bxslider-banners img {
	width: 100%;
}*/

.bx-wrapper {
    margin: 0 auto !important;

    .bx-controls-direction a {
        margin-top: 150px !important;
        background-image: none;
        cursor: pointer;
        height: 40px !important;
        text-indent: 0 !important;
        width: 40px !important;

        i {
            border: 1px solid $unipol-green;
            color: $unipol-green;
            padding: 0 .25rem;
        }
    }

    .bx-prev {
        left: -45px !important;
    }

    .bx-next {
        right: -49px !important;
    }

    .bx-pager {
        bottom: 0 !important;
        top: auto !important;

        &.bx-default-pager a {
            background: none !important;
            height: 8px !important;
            width: 8px !important;
            border: 2px solid $unipol-off-white !important;
            margin: 0 3px !important;

            &.active {
                background: $white !important;
            }
        }
    }
}

.carousel-next,
.carousel-prev {
    @extend .btn-border !optional;
    color: $unipol-green;
    display: block;
    position: absolute;
    top: 9%;
    z-index: 1;
    border: 1px solid;
	cursor: pointer;
	padding: 0 .25rem;
}

.carousel-next {
    right: rem-calc(5);
}

.carousel-prev {
    left: rem-calc(5);
}

@include media-breakpoint-up(lg) {
    .carousel-next {
        right: rem-calc(15);
    }

    .carousel-prev {
        left: rem-calc(15);
    }
}

@include media-breakpoint-up(xl) {
    .carousel-next {
        right: rem-calc(25);
    }

    .carousel-prev {
        left: rem-calc(25);
    }
}